/* BREAKPOINTS */
$small: 0px;
$mobileMedium: 350px;
$mobileLarge: 400px;
$tablet: 764px;
$laptop: 1024px;
$laptopBig: 1280px;
$desktop: 1440px;
$huge: 1560px;
$giant: 1920px;

$iPhone5Height: 568px;


/* Z-INDEX */
$zHeader: 9000;  
$zBelow: -1;
$zBottomlessPit: -9000;


/* SIZES */
$columns: 16;
$columnWidth: 130px;
$gutter: 24px;
$gutterVertical: 1.5rem;
$pageGutter: $gutter;
$widthPage: $huge;
$widthPageInner: $columnWidth * $columns + $gutter * ( $columns - 1) + $pageGutter * 2;

$headerHeight: 5.5rem;
$headerHeight: 2.75rem;


/* COLORS */
$white: #fff;
$grey: #D8D8D8;
$lightGrey: #ECEBE6;
$regularGrey: #A5A5A5;
$mediumGrey: #6C6C6C;
$darkGrey: #808080;
$black: #1E1E1E;
$red: #F36E5D;
$bgColor: $white;
$color: $black;


/* EASING */
$easing: cubic-bezier(0.455, 0.03, 0.515, 0.955);

/* FONTS */
$ffSerif: "IvarDisplay-Regular", serif;
$ffSerifMedium: "IvarDisplay-Medium", serif;
$ffSansSerif: "AkzidGroBEReg", serif, akzidenz-grotesk, sans-serif;
$ffSansSerif: akzidenz-grotesk, sans-serif;
$fwSansSerif: 400;
$fwSansSerifMedium: 500;

$ffBody: $ffSansSerif;
$ffHead: $ffSansSerif;