.c-footer {
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  background-color: $black;
  /* border-top: 1px solid $darkGrey; */
  font-size: 0.875rem;
  letter-spacing: -0.02857142857em;
  line-height: 1.0714285714;

  @mixin bp $tablet {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  &,
  a {
    color: $white;
  }

  a {
    text-decoration: none;
  }

  &__row {
    flex-wrap: wrap;
    justify-content: space-between;

    &--relayout {
      @mixin bp $tablet {
        flex-direction: row-reverse;
      }
    }

    &:first-child {
      margin-bottom: 11.875rem;

      @mixin bp $tablet {
        margin-bottom: 10rem;
      }
    }

    &:last-child {
      margin-top: .5em;

      @mixin bp $tablet {
        margin-top: 5rem;
      }
    }
  }

  &__home-link {
    display: block;
    width: 7em;
    height: 1.3125em;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    @mixin bpMax $tablet {
      margin-top: 2.5em;
      color: $darkGrey;
    }

    p {
      max-width: 18em;
    }

    p:first-child {
      margin-top: 0;
    }
  }

  &__address {
    @mixin bpMax $tablet {
      width: calc((100% - $gutter) / 2);
    }

    &__city,
    &__address {
      margin: 0;
    }

    & + & {
      margin-top: 1.25em;
    }
  }

  &__signup {
    @mixin bpMax $tablet {
      margin-bottom: 2.85em;
    }
  }

  &__brand,
  &__copyright {
    p {
      margin: 0;
    }
  }

  &__email,
  &__some-links {
    @mixin bpMax $tablet {
      display: none;
    }
  }

  &__some-links {
    &__items {
      display: flex;
    }

    &__item {
      & + & {
        margin-left: 1em;
      }
    }
  }

  &__copyright {
    text-align: right;
  }
}