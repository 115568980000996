@import "../global/variables.css";
@import "../global/mixins.css";

.c-cursor {
  &__bg,
  &__label {
    position: fixed;
    top: -50px;
    left: -50px;
    z-index: $zHeader;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    pointer-events: none;
  }

  &__bg--red {
    background-color: $red;
  }

  &__bg--text {
    background-color: $black;
    transform: translate(50vw, 50vh);
  }

  &__label {
    /* z-index: 2; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color;
    text-transform: uppercase;
    font-weight: $fwSansSerifMedium;

    &__text {
      margin: 0;
    }
  }
}