.c-newsletter-signup {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    will-change: background-color;
    transition: background-color 200ms ease-in-out;
    background-color: $mediumGrey;
  }

  &:focus-within:after {
    background-color: $white;
  }

  &__headline {
    display: block;
    margin-bottom: 1em;

    @mixin bp $tablet {
      margin-bottom: 2em;
    }
  }

  .klaviyo-form input[type=email],
  .klaviyo-form input[type=email]::placeholder,
  .klaviyo-form p,
  .klaviyo-form span:not([role="alert"]),
  .c-input__placeholder,
  .c-input {
    padding: 0 !important;
    font-size: 2.5rem !important;

    @mixin bp $tablet {
      font-size: 3.75rem !important;
    }
  }

  .c-input__placeholder {
    color: $darkGrey;
    display: none;
  }

  .klavio-form input[type=email],
  .klavio-form input[type=email]::placeholder,
  input,
  .c-input {
    position: relative;
    bottom: -.025em;
    left: -5px;
    width: calc(100% - 44px);
    outline: none;
    color: $darkGrey;
    will-change: color, opacity;
    /* Opacity transition because of the mobile menu toggle animation set in header.css */
    transition: color 200ms ease-in-out, opacity 500ms 700ms easeInOutSine;
    border: none !important;
    border-bottom: none !important;

    @mixin bp $tablet {
      bottom: 5px;
      left: -5px;
      width: calc(100% - 3.4375rem);
    }

    &:focus {
      color: $white;

      & ~ .c-input__placeholder {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .c-arrow,
  input[type=submit] {
    position: absolute;
    right: -15px;
    display: block;

    @mixin bp $tablet {
      right: -2px;
    }
  }

  input[type=submit] {
    bottom: 8px;
    border: none;
    min-width: 44px;
    min-height: 44px;
    color: transparent;
    background: transparent;

    @mixin bp $tablet {
      bottom: 19px;
    }

    
  }

  .c-arrow {
    bottom: 14px;
    height: 2rem;
    pointer-events: none;

    @mixin bp $tablet {
      bottom: 19px;
      height: 2.85rem;
    }
  }

  /* Klaviyo overrides */
  .klaviyo-form[class*=form-version-cid] {
    & > *[class*=kl-private-reset-css] {
      transform: none !important;
    }
  }

  .klaviyo-form span[role="alert"] {
    background-color: transparent;
  }

  form.klaviyo-form {    
    padding: 0 !important;

    p,
    span,
    input[type=email] {
      text-align: unset !important;
      font-family: unset !important;
      font-weight: unset !important;
      letter-spacing: unset !important;
    }

    p,
    span {
      color: $white !important;
    }

    input[type=email] {
      position: relative;
      left: -2px !important;
      height: unset !important;
      background-color: unset !important;
      border-radius: unset !important;
      color: unset !important;

      @mixin bp $tablet {
        bottom: 5px;
        left: -3px !important;  
      }

      &:focus {
        box-shadow: unset !important;
      }

      & + div {
        display: none;
      }
    }

    div[data-testid*=form-component] {
      padding: unset !important;
    }

    & > .needsclick {
      min-height: auto !important;

      & > div {
        &:first-child {
          width: calc(100% - 3.625rem);
        }

        &:last-child {
          position: absolute !important;
          right: 0;
          width: 3.625rem;
          overflow: hidden;
          opacity: 0;
        }
      }
    }
  }
}