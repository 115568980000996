.c-main-nav {
  letter-spacing: -.04em;

  /* @media screen and (height <= $iPhone5Height) {
    margin-bottom: 3rem;
  } */
  
  .c-header & {
    font-size: 2.5rem;
    line-height: 1.05;

    @mixin bp $tablet {
      font-size: 1.5rem;
      letter-spacing: -.03em;
    }

    &__list-item {
      will-change: opacity;
      transition: opacity 500ms 700ms easeInOutSine;
      
      @mixin bpMax $tablet {
        opacity: 0;
      }

      .s-menu-open & {
        opacity: 1;

        @each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 {
          &:nth-child($(i)) {
            transition-delay: calc($(i) * 50ms + 450ms);
          }
        }
      }
    } 
  }

  .c-footer & {
    font-size: 1.625rem;
    line-height: 1.08;

    @mixin bp $tablet {
      font-size: 1.875rem;
      line-height: 1;
      letter-spacing: -.04em;
    } 
  }

  &__toggle,
  .c-main-nav__link {
    will-change: color;
    transition: color 250ms easeInOutQuad;
  }

  &__toggle {
    font-size: 1.5rem;
    text-decoration: none;
    
    @mixin bp $tablet {
      display: none;
    }

    .s-dark-header & {
      color: $white;
    }
  }

  &,
  &__list-item,
  &__link {
    .c-header & {
      @mixin bp $tablet {
        height: 100%;
      }
    }
  }


  &__list {
    @mixin bp $tablet {
      height: $headerLineHeight;
    }

    .c-header & {
      @mixin bp $tablet {
        display: flex;
      }
    }
  }

  &__list &__list {
    position: absolute;
    display: none;
    border-bottom: 1px solid currentColor;
    left: 0;
    width: 100%;
    padding-left: $pageGutter;
    font-size: 1.125rem;
    letter-spacing: -0.03777777778em;

    @mixin bp $tablet {
      font-size: 1.5rem;
    }

    .c-main-nav__link {
      color: $mediumGrey;
    }
  }

  .current-menu-item + &__list,
  .current_page_item + &__list,
  .current-menu-ancestor + &__list,
  .current-menu-parent + &__list,
  .current_page_parent + &__list,
  .current_page_ancestor + &__list {
    display: flex;
  }

  &__list-item {
    & + & {
      .c-header & {
        @mixin bp $tablet {
          margin-left: 1em;
        }
      }
    }
  }

  &__link {
    text-decoration: none;

    @mixin bp $tablet {
      display: flex;
      align-items: center;  
    }

    .s-dark-header & {
      color: $white;
    }

    &.current-menu-item,
    &.current_page_item,
    &.current-menu-ancestor,
    &.current-menu-parent,
    &.current_page_parent,
    &.current_page_ancestor {
      font-family: $ffSerif;
      letter-spacing: 0;
    }
  }
}