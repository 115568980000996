@import "../global/variables.css";

$headerLineHeight: 2.75rem;

.c-header {
  transition: color 400ms easeInOutQuad, border-color 400ms easeInOutQuad;

  @mixin bp $tablet {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zHeader;
    height: $headerHeight;
  }

  /* Menu border bottom */
  &:after {
    /* content: ""; */
    position: absolute;
    top: $headerLineHeight;
    left: 0;
    height: 1px;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }

  

  .s-dark-header &,
  .s-dark-theme & {
    color: $white;
  }

  &__content,
  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform 400ms easeInOutQuad;
  }

  &__content {
    @mixin bp $tablet {
      border-bottom: 1px solid currentColor;
    }

    .s-header-hidden & {
      @mixin bp $tablet {
        transform: translateY(-200%);
      }
    }
  }

  &__bg {
    z-index: $zBelow;
    pointer-events: none;

    @mixin bpMax $tablet {
      position: fixed;
      z-index: $zHeader;
    }

    @mixin bp $tablet {
      will-change: transform;
      transition: transform 400ms easeInOutQuad;
      /*
      -1px because of the border height. If the bg is simply
      beeing translated 100% it's gonna sit on top of the border
      */
      /* transform: translateY(calc($headerHeight - 1px)); */
    }

    .s-header-bg-visible & {
      @mixin bp $tablet {
        transform: translateY(0);
      }
    }

    .s-menu-open & {
      pointer-events: auto;
    }

    &:before,
    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translate3d(0, 0, 0);
    }

    /* The background color */
    &:before {
      content: "";
      top: 0;
      background-color: $bgColor;
      transform: translateY(-100%);
      will-change: transform, background-color;
      transition: transform 1000ms easeInOutQuad, background-color 400ms easeInOutQuad;

      @mixin bp $tablet {
        transform: translateY(calc(-100% - $headerHeight));
        background-color: $bgColor;
        z-index: -1;
      }

      .s-dark-header &,
      .s-dark-theme & {
        background-color: $color;

        @mixin bp $tablet {
          background-color: $color;
        }
      }

      .s-header-bg-visible & {
        @mixin bpMax $tablet {
          transform: translateY(calc(3rem - 100%));
        }

        @media screen and (width < $tablet and height <= $iPhone5Height) {
          transform: translateY(calc(4rem - 100%));
        }

        @mixin bp $tablet {
          transform: translateY(0);
        }
      }

      .s-menu-open & {
        @mixin bpMax $tablet {
          transform: translateY(0);
        }
      }
    }

    /* The border */
    &:after {
      height: calc(100% - 1px);
      border-bottom: 1px solid $black;
      transform: translateY(calc(3rem - 100%));
      will-change: transform, border-color;
      transition: transform 1000ms easeInOutQuad, border-color 400ms easeInOutQuad;

      @mixin bpMax $tablet {
        content: "";
      }

      @media screen and (width < $tablet and height <= $iPhone5Height) {
        transform: translateY(calc(4rem - 100%));
      }

      .s-dark-header &,
      .s-dark-theme & {
        border-color: $white;
      }

      .s-header-hidden & {
        transform: translateY(-100%);
      }

      .s-menu-open & {
        transform: translateY(0);
      }
    }
  }

  

  &__primary {
    position: relative;
    display: flex;
    justify-content: space-between;

    @mixin bpMax $tablet {
      position: fixed;
      top: 0;
      z-index: calc($zHeader + 1);
      display: flex;
      align-items: flex-end;
      height: 3rem;
      padding-bottom: 0.5555rem;
      will-change: transform;
      transition: transform 400ms easeInOutQuad;
    }

    @media screen and (width < $tablet and height <= $iPhone5Height) {
      height: 4rem;
    }

    .s-header-hidden:not(.s-menu-open) & {
      @mixin bpMax $tablet {
        transform: translateY(-100%);
      }
    }
  }

  &__menu {
    display: flex;

    @mixin bpMax $tablet {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zHeader;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 6.5rem;
      padding-bottom: 2rem;
      will-change: transform;
      transform: translateY(-100%);
      transition: transform 1000ms easeInOutQuad;
    }

    @media screen and (width < $tablet and height <= $iPhone5Height) {
      padding-top: 6rem;
    }

    @mixin bp $tablet {
      position: relative;
      align-items: center;
      height: 100%;
    }

    .s-menu-open & {
      @mixin bpMax $tablet {
        transform: translateY(0);
      }
    }

    &__mobile {
      @mixin bp $tablet {
        display: none;
      }

      .s-dark-header & {
        color: $white;
      }
      
      &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 2.5rem;

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__home-link {
    display: block;
    margin-right: 0;
    margin-left: auto;

    @mixin bp $tablet {
      position: absolute;
      top: .55em;
      right: calc($pageGutter - 9px);
      z-index: 1;
    }

    .c-logo {
      width: auto;
      height: 1.325rem;

      .s-dark-header & {
        color: $white;
      }
    }
  }

  .c-newsletter-signup__headline,
  .c-newsletter-signup:after,
  .c-arrow,
  .c-form__field,
  .c-header__menu__mobile__bottom {
    will-change: opacity;
    transition: opacity 500ms 700ms easeInOutSine;

    @mixin bpMax $tablet {
      opacity: 0;
    }

    .s-menu-open & {
      opacity: 1;
    }
  }

  .c-newsletter-signup__headline {
    .s-menu-open & {
      transition-delay: calc(6 * 50ms + 450ms);
    }
  }

  .c-newsletter-signup:after,
  .c-arrow,
  .c-form__field {
    .s-menu-open & {
      transition-delay: calc(7 * 50ms + 450ms);
    }
  }

  .c-header__menu__mobile__bottom {
    .s-menu-open & {
      transition-delay: calc(8 * 50ms + 450ms);
    }
  }

  .c-newsletter-signup {
    @mixin bp $tablet {
      display: none;
    }

    .c-arrow path {
      stroke: $black;

      .s-dark-header & {
        stroke: $white;
      }
    }

    .c-input {
      &,
      &:focus {
        color: $color;
      }

      .s-dark-header &,
      .s-dark-theme & {
        &,
        &:focus {
          color: $white;
        }
      }
    }
  }
}

/* Menu not open */
.s-header-bg-visible,
.s-header-hidden {
  .c-header {
    &__bg:before,
    &__bg:after {
      transition-duration: 400ms;
    }

    &__menu {
      transition-duration: 400ms;
    }
  }
}

.s-menu-open,
.s-menu-closing {
  .c-header {
    &__bg:before,
    &__bg:after {
      transition-duration: 600ms;
    }

    &__menu {
      transition-duration: 700ms;
    }
  }
}