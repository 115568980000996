.l-columns-container {
  display: flex;
  max-width: $widthPage;

  &:not([class*="l-column--"]) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &--outmost {
    padding-right: $pageGutter;
    padding-left: $pageGutter;
  }
}

@each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
  .l-column--$(i) {
    width: calc(
      (100% - $gutter * ($columns - 1)) / $columns * $i + ($i - 1) * $gutter
    );
  }
}

@each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
  .l-tablet-column--$(i) {
    @mixin bp $tablet {
      width: calc(
        (100% - $gutter * ($columns - 1)) / $columns * $i + ($i - 1) * $gutter
      );
    }
  }
}

@each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
  .l-laptop-column--$(i) {
    @mixin bp $laptop {
      width: calc(
        (100% - $gutter * ($columns - 1)) / $columns * $i + ($i - 1) * $gutter
      );
    }
  }
}

@each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
  .l-laptop-big-column--$(i) {
    @mixin bp $laptopBig {
      width: calc(
        (100% - $gutter * ($columns - 1)) / $columns * $i + ($i - 1) * $gutter
      );
    }
  }
}

@each $i in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 {
  .l-desktop-column--$(i) {
    @mixin bp $desktop {
      width: calc(
        (100% - $gutter * ($columns - 1)) / $columns * $i + ($i - 1) * $gutter
      );
    }
  }
}
