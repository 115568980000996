@import "../global/variables.css";

.c-word-reveal {
  & > span {
    margin-bottom: -.1em;
    padding-right: 0.05em;
    overflow: hidden;
  }

  span {
    display: inline-block;
  }

  span span {
    will-change: transform, opacity;
    transition: transform 500ms easeOutCubic, opacity 500ms easeOutCubic;
    transform: translateY(100%);
    opacity: 0;

    .c-headline--large & {
      transition-duration: 600ms;
    }
  }

  .s-revealed span {
    transform: translateY(0);
    opacity: 1;
  }
}
