body {
  transition: background-color 1000ms ease-in-out, color 1000ms ease-in-out;

  &.s-menu-open {
    overflow: hidden;
  }
}

.s-dark-theme {
  background-color: $color;
}

.c-logo {
  path {
    fill: currentColor;
  }
}

.c-main {
  min-height: 100vh;
}

.c-close-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  transition: transform 200ms ease-in-out;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 1.3125rem;
    height: 1px;
    background-color: $darkGrey;
  }

  &:after {
    transform: rotate(90deg);
  }

  .s-toggled & {
    transform: rotate(45deg)
  }
}

.c-cover-image-container {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  background-color: $lightGrey;
}

.c-cover-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
}

.s-hidden {
  display: none;
}

.touch-device {
  .c-cursor {
    display: none;
  }
}

figure {
  width: 100%;
}