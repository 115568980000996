@font-face {
  font-family: 'IvarDisplay-Regular';
  src:  url('../../fonts/IvarDisplay-Regular.woff2') format('woff2'),
        url('../../fonts/IvarDisplay-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IvarDisplay-Medium';
  src:  url('../../fonts/IvarDisplay-Medium.woff2') format('woff2'),
        url('../../fonts/IvarDisplay-Medium.woff') format('woff');
}

.f-red {
  color: $red;
}

.f-grey {
  color: $regularGrey;
}

.f-dark-grey {
  color: $darkGrey;
}

body {
  color: $color;
  font-family: $ffBody;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @mixin bp $tablet {
    font-size: 1.125rem;
    line-height: 1.1666666667;
    letter-spacing: -.044em;
  }

  &.s-dark-theme {
    background-color: $black;
    color: $white;
  }
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

a {
  color: $color;
  
  .s-dark-theme & {
    color: $white;
  }
}