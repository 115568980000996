.c-form__field {
  position: relative;
}

.c-input {
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid currentColor;
  font-family: $ffSansSerif;

  &,
  &__placeholder {
    line-height: 1;
    letter-spacing: -.04em;
  }

  &__placeholder {
    position: absolute;
    bottom: -.13em;
    left: -.1em;
    display: block;
    pointer-events: none;

    + input::placeholder {
      color: transparent;
    }
  }
}

::placeholder {
  color: currentColor;
}